import React from 'react'
import { render } from 'react-dom'
import App from '../../cra-template-rmw/template/src/App'

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.querySelector('#demo')
)
